import React from "react";

import { BsChevronDown } from "react-icons/bs";

const ListViewItemContainer = ({ data }) => {
  const handleClick = (e) => {
    const el = e.target.nextSibling;

    const prevEl = document.querySelectorAll(".active.list-view-item-desc")[0];
    if (el.classList.contains("active")) {
      el.classList.remove("active");
    } else if (prevEl) {
      prevEl.classList.remove("active");
      el.classList.add("active");
    } else {
      el.classList.add("active");
    }
  };

  return (
    <div className="list-view-item-container">
      <p className="list-view-item-title" onClick={handleClick}>
        {data.title}{" "}
        <BsChevronDown
          className="drop-down"
          style={{ pointerEvents: "none" }}
        />
      </p>
      <div className={"list-view-item-desc"}>
        <ul className="list-view-sublist">
          {data.list.map((e, i) => {
            return (
              <li className="list-view-sublist-item" key={i}>
                {e}
                <button disabled={true} className="enroll-btn">
                  ENROLL
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ListViewItemContainer;
