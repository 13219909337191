import React from "react";

import { BsChevronDown } from "react-icons/bs";
import illustration from "./assets/images/illustration4.png";

import training from "./assets/data/training";
import { useGlobalContext } from "./context";
import ListViewContainer from "./ListViewContainer";

const Training = () => {
  const { windowSize } = useGlobalContext();
  return (
    <section className="content-container" id="training">
      <div
        className={`center-area ${
          windowSize <= 1050 ? "flex-area" : "two-section-grid"
        }`}
      >
        <div className="text left">
          <h1 className="section-heading">Training</h1>
          <div className="illustration-container">
            <img src={illustration} alt="" className="section-illustration" />
          </div>
        </div>
        <div className="right">
          <ListViewContainer data={training} />
        </div>
      </div>
      <a href="#contact" className="next-section">
        <BsChevronDown />
      </a>
    </section>
  );
};

export default Training;
