import React from "react";
import { BsChevronDown } from "react-icons/bs";

import { useGlobalContext } from "./context";

import "./css/Contact.css";

import socials from "./assets/data/socials";
import illustration from "./assets/images/illustration1.png";

const ContactUs = () => {
  const { windowSize } = useGlobalContext();
  return (
    <section className="content-container" id="contact">
      <div
        className={
          "center-area" +
          (windowSize < 768 ? " flex-area" : " two-section-grid")
        }
      >
        <div className="text left">
          <h1 className="section-heading">Reach out to us</h1>
          <ul className="contact-list">
            {socials.map((el) => {
              return (
                <li className="contact-link" key={el.id}>
                  <a href={el.link} rel="noreferrer" target="_blank">
                    {el.icon} {el.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="right">
          <img src={illustration} alt="img" className="section-illustration" />
        </div>
      </div>
      <a
        href="#home"
        className="next-section top"
        style={{ transform: "rotate(180deg)" }}
      >
        <BsChevronDown />
      </a>
    </section>
  );
};

export default ContactUs;
