import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Header from "./Header";
import Home from "./Home";
// import Career from "./Career";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import WhatsappFloatIcon from "./WhatsappFloatIcon";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="pp" element={<PrivacyPolicy />} />
        </Routes>
        <WhatsappFloatIcon />
        <Footer />
      </Router>
    </>
  );
};

export default App;
