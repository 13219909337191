import React from "react";
import { Link } from "react-router-dom";

import "./css/Footer.css";
import logo from "./assets/images/logo.png";

import socials from "./assets/data/socials";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="socials column-flex">
          <p className="footer-list-title">Follow Us</p>
          {socials.map((el) => {
            return (
              <a
                href={el.link}
                target="_blank"
                className="footer-link"
                rel="noreferrer"
                key={el.id}
              >
                {el.name}
              </a>
            );
          })}
        </div>
        <img src={logo} alt="mkcy logo" className="footer-logo" />
        <div className="links column-flex">
          <p className="footer-list-title">Links</p>
          <Link to="/pp" className="footer-link">
            Privacy Policy
          </Link>
          <a href="/" rel="noreferrer" target="_blank" className="footer-link">
            Careers
          </a>
          <a href="/" rel="noreferrer" target="_blank" className="footer-link">
            Blog
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-text">
          COPYRIGHT © {new Date().getFullYear()} MKCY INDUSTRIAL SOLUTIONS - ALL
          RIGHTS RESERVED
        </p>
        <p className="footer-bottom-text light">
          591 Salesh Farm Colony, Pilkhuwa, Hapur, Uttar Pradesh, India, 245304
        </p>
      </div>
    </footer>
  );
};

export default Footer;
