import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="content-container">
      <div className="center-area flex-area" id="pp">
        <div className="text">
          <h1 className="section-heading">Privacy Policy</h1>
          {/* <div className="policy-text"> */}
          <h2 className="sub-heading">Introduction</h2>
          <p className="text">
            At MKCY Industrial Solutions Pvt Ltd, we understand the importance
            of privacy and take the protection of our Clients' personal
            information very seriously. This Privacy Policy outlines our
            practices regarding the collection, use, and storage of data. We are
            committed to ensuring the privacy and security of our Clients and
            their information, and to being transparent about our privacy
            practices.
          </p>
          <h2 className="sub-heading">Collection of Data</h2>
          <p className="text">
            We collect personal information from our Clients when they use our
            services or interact with us in other ways. This information may
            include their name, email address, phone number, and other contact
            information. We may also collect information about their use of our
            services, such as the frequency and duration of their use. We
            collect this information in order to provide our Clients with the
            best possible experience, and to better understand and improve our
            services.
          </p>
          <h2 className="sub-heading">Use of Data</h2>
          <p className="text">
            We do not sell or share our Clients' information with any third
            party. The data we collect is used solely for our internal use, such
            as for the purpose of providing our services and improving our
            products. We may use data to communicate with our Clients, for
            example to send them important information about our services or to
            provide customer support. We take appropriate measures to protect
            the security of our Clients' information and to prevent unauthorized
            access, disclosure, or misuse. This includes using secure servers,
            firewalls, to protect data.
          </p>
          <h2 className="sub-heading">Your Rights</h2>
          <p className="text">
            You have the right to access, update, or request deletion of your
            personal information. You can do this by contacting us at the email
            address provided in this Privacy Policy. We will respond to your
            request within a reasonable timeframe.
          </p>
          <h2 className="sub-heading">Changes to this Privacy Policy</h2>
          <p className="text">
            We may make changes to this Privacy Policy from time to time, in
            order to reflect changes to our privacy practices or to comply with
            legal requirements. We will notify our Clients of any changes to
            this Privacy Policy by posting an updated version on our website.
          </p>
          <h2 className="sub-heading">Contact Information</h2>
          <p className="text">
            If you have any questions about this Privacy Policy, or if you would
            like to make a request to access, update, or delete your personal
            information, you can contact us at contact@mkcysolutions.com
          </p>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default PrivacyPolicy;
