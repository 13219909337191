import React from "react";

import "./css/Carousel.css";
import Card from "./Card";

const Carousel = ({ data }) => {
  return (
    <div className="carousel">
      {data.map((el) => {
        return <Card data={el} key={el.id} />;
      })}
    </div>
  );
};

export default Carousel;
