import React from "react";
import { BsChevronDown } from "react-icons/bs";

import illustration from "./assets/images/illustration3.png";
import { useGlobalContext } from "./context";

const About = () => {
  const { windowSize } = useGlobalContext();
  return (
    <section className="content-container" id="about">
      <div
        className={`center-area ${
          windowSize <= 1050 ? "flex-area" : "two-section-grid"
        }`}
      >
        <div className="text">
          <h1 className="section-heading">About Us</h1>
          <p className="section-text-content">
            <strong>MKCY Industrial Solutions Pvt. Ltd.</strong> is a team of
            cybersecurity consultants who work together to provide cybersecurity
            with the objective of providing process improvement and information
            security consulting services. Each one of our consultants has
            extensive experience in their respective domains. Our consultants,
            apart from knowledge of the subject, have extensive software
            development experience. It is the trusted partner for enterprises
            and individuals, seeking to protect their brand, business contunity
            and integrity from unprecedented cyber-attacks. We are experts in
            conducting a thorough examination of a company's IT infrastructure
            and providing effective cyber security solutions.
          </p>
        </div>
        <div className="image">
          <img
            src={illustration}
            alt="about us"
            className="section-illustration"
          />
        </div>
      </div>
      <a href="#services" className="next-section">
        <BsChevronDown />
      </a>
    </section>
  );
};

export default About;
