import React from "react";

import logo from "./assets/images/logo.png";
import About from "./About";
import Services from "./Services";
import Training from "./Training";
import ContactUs from "./ContactUs";

const Home = () => {
  return (
    <main className="scroll-container">
      <section className="content-container hero-container" id="home">
        <div className="hero">
          <div>
            <img src={logo} alt="mkcy" className="center-logo" />
            <h1 className="tagline">STAY SAFE IN TECH SPACE</h1>
          </div>
        </div>
      </section>
      <About />
      <Services />
      <Training />
      <ContactUs />
    </main>
  );
};

export default Home;
