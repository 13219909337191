import { SiFacebook, SiInstagram, SiLinkedin, SiTwitter } from "react-icons/si";
import { IoMailOutline } from "react-icons/io5";
import { SlPhone } from "react-icons/sl";

const socials = [
  {
    id: "5",
    name: "+91 8279825323",
    link: "tel:+918279825323",
    icon: <SlPhone />,
  },
  {
    id: "4",
    name: "Email",
    link: "mailto:contact@mkcysolutions.com",
    icon: <IoMailOutline />,
  },
  {
    id: "0",
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/mkcysolutions",
    icon: <SiLinkedin />,
  },
  {
    id: "1",
    name: "Twitter",
    link: "https://twitter.com/mkcysolutions",
    icon: <SiTwitter />,
  },
  {
    id: "2",
    name: "Facebook",
    link: "https://facebook.com/mkcysolutions",
    icon: <SiFacebook />,
  },
  {
    id: "3",
    name: "Instagram",
    link: "https://instagram.com/mkcysolutions",
    icon: <SiInstagram />,
  },
];

export default socials;
