import React from "react";
import "./css/ListViewContainer.css";

import ListViewItemContainer from "./ListViewItemContainer";

const ListViewContainer = ({ data }) => {
  return (
    <div className="list-view-container">
      {data.map((el) => {
        return <ListViewItemContainer key={el.id} data={el} />;
      })}
    </div>
  );
};

export default ListViewContainer;
