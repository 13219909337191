import React from "react";

const Card = ({ data }) => {
  return (
    <div className="card">
      <p className="card-title">{data.title}</p>
      <ul className="card-list">
        {data.list.map((item, ind) => {
          return (
            <li key={ind} className="card-list-item">
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Card;
