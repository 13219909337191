import React, { useEffect } from "react";
import { VscMenu, VscChromeClose } from "react-icons/vsc";

import "./css/Header.css";
import logo from "./assets/images/logo.png";
import { useGlobalContext } from "./context";

const Header = () => {
  const { windowSize, isMenuOpen, setIsMenuOpen } = useGlobalContext();
  const active = () => {
    if (windowSize > 600 || isMenuOpen) {
      const li = document.querySelectorAll(".nav-link");
      const sec = document.querySelectorAll("section");
      let len = sec.length;
      while (--len && window.scrollY + 100 < sec[len].offsetTop) {}
      li.forEach((ltx) => ltx.classList.remove("active-nav-link"));
      li[len].classList.add("active-nav-link");
    }
  };

  const handleClick = (e) => {
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 200);
  };

  useEffect(() => {
    window.addEventListener("scroll", active);
    active();
  });

  return (
    <header id="header">
      <a href="/" className="logo" id="top">
        <img src={logo} alt="mkcy" style={{ width: "100%" }} />
      </a>
      <nav className={isMenuOpen ? "menu-open" : ""}>
        <a
          href="/#home"
          className="nav-link active-nav-link"
          onClick={handleClick}
        >
          Home
        </a>
        <a href="/#about" className="nav-link" onClick={handleClick}>
          About
        </a>
        <a href="#services" className="nav-link" onClick={handleClick}>
          Services
        </a>
        <a href="#training" className="nav-link" onClick={handleClick}>
          Training
        </a>
        <a href="#contact" className="nav-link" onClick={handleClick}>
          Contact Us
        </a>
      </nav>
      {isMenuOpen ? (
        <VscChromeClose
          className="menu-toggle-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      ) : (
        <VscMenu
          className="menu-toggle-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      )}
    </header>
  );
};

export default Header;
