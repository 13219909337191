import React from "react";

import { BsChevronDown } from "react-icons/bs";
import Carousel from "./Carousel";

import services from "./assets/data/services";

const Services = () => {
  return (
    <section className="content-container" id="services">
      <div className="center-area flex-area">
        <div className="text">
          <h1 className="section-heading">What we do?</h1>
          <Carousel data={services} />
        </div>
      </div>
      <a href="#training" className="next-section">
        <BsChevronDown />
      </a>
    </section>
  );
};

export default Services;
