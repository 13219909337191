const services = [
  {
    id: "0",
    title:
      "Security Assessment, Vulnerability Management & Penetration Testing Services",
    list: [
      "Network Architecture Design Review",
      "Network Vulnerability Assessment",
      "Network Penetration Testing",
      "Wireless Network Penetration Testing",
      "Web Application Penetration Testing",
      "Mobile Application Penetration Testing",
      "Static Application Security Testing (SAST)",
    ],
  },
  {
    id: "1",
    title: "Enterprise Content Management (ECM)",
    list: [
      "Managed Services",
      "Migration Services",
      "Cloud Services",
      "Digital Transformation Services",
    ],
  },
  {
    id: "2",
    title: "IT Security Audit, Risk Assessment & Security Compliance Services",
    list: [
      "IT Governance, Risk, and Compliance Assessment",
      "Information Security Managent Systems ",
      "Information Technology and Service Management",
      "Security Risk Management",
      "Data Privacy and GDPR",
    ],
  },
];

export default services;
