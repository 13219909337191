import React from "react";
import { SiWhatsapp } from "react-icons/si";

const WhatsappFloatIcon = () => {
  return (
    <a href="https://wa.me/918279825323" className="float-icon">
      <SiWhatsapp />
    </a>
  );
};

export default WhatsappFloatIcon;
