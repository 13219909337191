const training = [
  {
    id: "0",
    title: "Specialised Security Programs",
    list: [
      "Penetration Tester",
      "Reverse Engineering & Malware Analysis",
      "Web Application Penetration Testing",
      "IoT Penetration Testing",
      "Exploit Development",
      "Source Code Review & Patch Management",
    ],
  },
  {
    id: "1",
    title: "Enterprise Content Management (ECM)",
    list: [
      "ECM Development Training",
      "ECM Customization and Development",
      "ECM Administration Training",
      "ECM Customized Program Training",
    ],
  },
  {
    id: "2",
    title: "Programming Training",
    list: [
      "Programming with Python",
      "Data Analytics with Python",
      "Data Science with Python",
      "Machine Learning with Python",
      "Programing with C/C++",
    ],
  },
];

export default training;
